@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
}

input {
  font-family: 'Roboto', sans-serif !important;
}

input:disabled {
  background-color: transparent !important;
}

/* override tailwind img */
img.tableImage {
  max-width: none !important;
  max-height: 34px;
  margin: auto;
  cursor: pointer;
}

#tableFixHead { 
  overflow: auto;
  height: 600px;
  position: relative;
  border: 1px solid #c3c3c3;
  border-top: none;
}
#tableFixHead thead th { 
  position: sticky; 
  top: 0; 
  z-index: 6;
  background: #fff;
  border-top: solid 1px #c3c3c3;
  font-size: 12px;
  text-align: left;
}
#tableFixHead td:nth-child(1), #tableFixHead thead th:nth-child(1) {
  max-width: 34px !important;
  width: 34px;
  height: 50px;
}
#tableFixHead td:nth-child(2), #tableFixHead thead th:nth-child(2) {
  max-width: 100px !important;
  width: 100px;
}
#tableFixHead td:nth-child(2) a {
  cursor: default;
}
#topLineFiller {
  position: absolute;
  height: 1px;
  border-top: 1px solid #c3c3c3;
  width: 100%;
  z-index: 100;
  top: 0px;
  left: 0;
}

#tableViewProducts:fullscreen {
  background-color: #fff;
  width: 100vw;
  height: 100wh;
}

.container {
  max-width: 96vw;
}

#root {
  overflow: hidden;
}
.custom-file-input {
  color: transparent;
  border: 0px transparent !important;
  outline: none !important;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Browse files";
  width: 50%;
  border-radius: 9999px;
  background-color: #bf2152;
  padding: 1rem;
  align-self: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  margin: auto;
  outline: none !important;
  display: block;
  border: none !important;
}
.custom-file-input::after {
  outline: none;
  border: none !important;
  background: none !important;
}
#blocker {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  user-select: none;
  z-index: 100;
  display: none;
  background: rgba(255, 255, 255, 0.5);
}

section.wrapper.dark {
  background: #313134;
}

div.spinner {
  -moz-animation: rotate 10s infinite linear;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
  position: relative;
  display: block;
  margin: auto;
  width: 142px;
  height: 142px;
  margin-top: 45vh;
}
div.spinner i {
  -moz-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -webkit-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -moz-transform-origin: 50% 100% 0;
  -webkit-transform-origin: 50% 100% 0;
  transform-origin: 50% 100% 0;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  border: solid 6px transparent;
  border-bottom: none;
}
div.spinner i:nth-child(1) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  width: 44px;
  height: 22px;
  margin-top: -22px;
  margin-left: -22px;
  border-color: #2172b8;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}
div.spinner i:nth-child(2) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  width: 58px;
  height: 29px;
  margin-top: -29px;
  margin-left: -29px;
  border-color: #18a39b;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
}
div.spinner i:nth-child(3) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  width: 72px;
  height: 36px;
  margin-top: -36px;
  margin-left: -36px;
  border-color: #82c545;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
}
div.spinner i:nth-child(4) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  width: 86px;
  height: 43px;
  margin-top: -43px;
  margin-left: -43px;
  border-color: #f8b739;
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}
div.spinner i:nth-child(5) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  width: 100px;
  height: 50px;
  margin-top: -50px;
  margin-left: -50px;
  border-color: #f06045;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}
div.spinner i:nth-child(6) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  width: 114px;
  height: 57px;
  margin-top: -57px;
  margin-left: -57px;
  border-color: #ed2861;
  border-top-left-radius: 66px;
  border-top-right-radius: 66px;
}
div.spinner i:nth-child(7) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  width: 128px;
  height: 64px;
  margin-top: -64px;
  margin-left: -64px;
  border-color: #c12680;
  border-top-left-radius: 72px;
  border-top-right-radius: 72px;
}
div.spinner i:nth-child(8) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  width: 142px;
  height: 71px;
  margin-top: -71px;
  margin-left: -71px;
  border-color: #5d3191;
  border-top-left-radius: 78px;
  border-top-right-radius: 78px;
}

@-moz-keyframes rotate {
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rbt-menu {
  background: #fff;
  border: solid 1px #aaa;
  width: auto !important;
  z-index: 100;
}

#searchComponent {
  position: relative;
  z-index: 101;
}

#searchComponent [type=text], #searchComponent select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: rgb(156,163,175);
  border-width: 1px;
  padding: 0.1rem 0.3rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5rem;
  width: 170px;
  border-radius: 30px;
}

#mainSearch [type=text] {
  width: 300px;
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  border-radius: 20px;
}

.prodlistitem {
  user-select: none;
}

.prodlistitem.active {
  background-color: #f6e7eb;
}

tr:nth-child(even) {background: #FFF}
tr:nth-child(odd) {background: #FFF}

/**
 * CSS3 Loading Bar
*/

.bar {
    width: 0%; 
    height: 0.5rem; 
    border-radius: 9999px;
    background-color: #8b8b8b;
    background-size: 50px 50px;
    -webkit-animation: move 2s linear infinite;
    -moz-animation: move 2s linear infinite;
    -ms-animation: move 2s linear infinite;
    animation: move 2s linear infinite;
}

/**
 * Animate the stripes.
 */

@-webkit-keyframes move {
    0%   { background-position: 0 0; }
    100% { background-position: 50px 50px; }
}

@-moz-keyframes move {
    0%   { background-position: 0 0; }
    100% { background-position: 50px 50px; }
}

@-ms-keyframes move {
    0%   { background-position: 0 0; }
    100% { background-position: 50px 50px; }
}

@keyframes move {
    0%   { background-position: 0 0; }
    100% { background-position: 50px 50px; }
}

#tableFullscreenBtn {
  padding-left: 5px;
  padding-top: 5px;
  display: inline-block;
  margin-bottom: -7px;
  font-size: 15px !important;
}

#visibleColumnsContainer {
  margin-top: 4px;
  position: absolute;
  width: 730px;
  z-index: 1000;
  background: #fff;
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 0.75rem;
  border: solid 1px rgba(156,163,175,1);
  top: 37px;
  left: 20%;
}

#advancedSearchContainer {
  position: absolute;
  background: #fff;
  margin-top: 0px;
  z-index: 1001;
  width: 1130px;
  padding-top: 14px;
  padding-bottom: 7px;
  padding-left: 6px;
  border-radius: 0.75rem;
  border: solid 1px rgba(156,163,175,1);
}

#advancedSearchContainer input,
#advancedSearchContainer select {
  border-radius: 30px;
}

#uploadingProgressContainer {
  width: 760px;
  height: 520px;
  position: fixed;
  top: 420px;
  left: 50%;
  margin-top: -230px;
  margin-left: -380px;
  z-index: 2000;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #444;
  overflow: hidden;
  display: none;
}

#downloadProgressContainer {
  position: fixed;
  width: 600px;
  left: 50%;
  margin-left: -300px;
  background: rgb(202, 202, 202);
  height: 30px;
  bottom: 100px;
  text-align: center;
  font-weight: 500;
  line-height: 25px;
  border: 2px solid #032891;
  border-radius: 12px;
  z-index: 5000;
  overflow: hidden;
}
#downloadProgressBar {
  width: 0%;
  background: rgb(94, 120, 243);
  height: 100%;
  border-radius: 0px;
  position: absolute;
  top: 0px;
}

/* custom scrollbars */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #646464 #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/* override carousel default styles */
.BrainhubCarousel__arrows {
  padding: 5px !important;
}

.slick-next {
  right: -4px !important;
  border-radius: 50px !important;
  padding-top: 1px !important;
  z-index: 5;
}

.slick-prev {
  left: -3px !important;
  padding-top: 1px !important;
  border-radius: 50px !important;
  padding-right: 1px !important;
  width: 21px !important;
  z-index: 5;
}

.slick-next, .slick-next:focus, .slick-next:hover, .slick-prev, .slick-prev:focus, .slick-prev:hover {
  background: #007a52 !important;
}

#dropArea {
  /* border: dashed 1px #000; */
  border-radius: 10px;
  width: 70%;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  /* background: #eee; */
}
[type=checkbox] {
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 0.8rem;
  width: 0.82rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0;
  cursor: pointer;
}

#loginMsg {
  color: #384ed0;
}
/* Animation Three */
.three span {
	opacity: 0;
	transform: translate(-300px, 0) scale(0);
	animation: sideSlide 1.1s forwards;
}
.animate span {
	display: inline-block;
}
.animate span:nth-of-type(2) {
	animation-delay: .05s;
}
.animate span:nth-of-type(3) {
	animation-delay: .1s;
}
.animate span:nth-of-type(4) {
	animation-delay: .15s;
}
.animate span:nth-of-type(5) {
	animation-delay: .2s;
}
.animate span:nth-of-type(6) {
	animation-delay: .25s;
}
.animate span:nth-of-type(7) {
	animation-delay: .3s;
}
.animate span:nth-of-type(8) {
	animation-delay: .35s;
}
.animate span:nth-of-type(9) {
	animation-delay: .4s;
}
.animate span:nth-of-type(10) {
	animation-delay: .45s;
}
.animate span:nth-of-type(11) {
	animation-delay: .5s;
}
.animate span:nth-of-type(12) {
	animation-delay: .55s;
}
.animate span:nth-of-type(13) {
	animation-delay: .6s;
}
.animate span:nth-of-type(14) {
	animation-delay: .65s;
}
.animate span:nth-of-type(15) {
	animation-delay: .7s;
}
.animate span:nth-of-type(16) {
	animation-delay: .75s;
}
.animate span:nth-of-type(17) {
	animation-delay: .8s;
}
.animate span:nth-of-type(18) {
	animation-delay: .85s;
}
.animate span:nth-of-type(19) {
	animation-delay: .9s;
}
.animate span:nth-of-type(20) {
	animation-delay: .95s;
}
@keyframes sideSlide {
	60% {
		transform: translate(20px, 0) scale(1);
		color: #008cff;
	}
	80% {
		transform: translate(20px, 0) scale(1);
		color: #0047b3;
	}
	99% {
		transform: translate(0) scale(1.2);
		color: #002652;
	}
	100% {
		transform: translate(0) scale(1);
		opacity: 1;
		color: #384ed0;
	}
}
#welcomeMsg {
  opacity: 0;
  animation: showWelcome 1.5s forwards;
}
@keyframes showWelcome {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.accordion {
  border: none;
  border-radius: 0;
}

.accordion__button {
  background-color: white;
}

img.shelfTemplateThumb {
  width: 90%;
  cursor: pointer;
  opacity: .6;
  float: left;
}
img.shelfTemplateThumb:hover {
  opacity: 1;
}
img.shelfTemplateThumb.selected {
  border: solid 1px red;
  opacity: 1;
}

#shelfTemplateSelector {
  height: 500px;
  width: 100%;
  overflow-y: auto;
}

.x-spreadsheet-overlayer {
  overflow: scroll !important;
}

.ruler-line {
  position: absolute; 
  left: 0px; 
  width: 100%; 
  height: 5px; 
  background: #00000080; 
  z-index: 99999; 
  cursor: ns-resize
}

#visibleColumnsContainer div {
  display: flex;
  align-items: center;
}

.shelfTemplateDiv {
  width: 200px;
  height: 130px;
  padding: 2px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}